import React from "react";
import { Button } from '@atoms'

const ButtonWrapper = ({ cta, background }) => {
  let btnVariant 
  if (background==="dark" && cta?.variant === "primary") {
    btnVariant = "secondary"
  } else if (background === "dark" && cta?.variant === "secondary") {
    btnVariant = "tertiary"
  } else {
    btnVariant = cta?.variant
  }

  return (
    <div className='button-wrapper'>
      <Button
        ctaLink={ cta?.link }
        ctaVariant={btnVariant}
        {...cta}
      >
        {cta?.label}
      </Button>
    </div>
  )
}

export default ButtonWrapper 