import React, { useState } from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { graphql, Link } from "gatsby"
import { Text } from "@atoms"
import ModalVideo from "react-modal-video"
import { VideoFrame } from "../styles/components/VideoFrame"
import { BlogSocialShareRow } from "../components/Blog/BlogDetail/BlogSocialShareRow"
import ConversionPanelStandard from "@components/ConversionPanel/ConversionPanelStandard"
import { postDetailResolveRichText } from "../utils/resolve-rich-text/blogDetailResolveRichText"
import {
  Heading,
  Kicker,
  HeroWrapper,
  VideoSectionWrapper,
  BreadCrumbFlex,
  IconImg,
  ThumbnailWrapper,
} from "./styles/videoDetail.styled"
import OptimizedImage from "../utils/optimizedImage"

import { validatePlay } from "../utils/validateVideo"
import onKeyDown from "../utils/onKeyDown"

const VideoDetailTemplate = ({ data }) => {
  const [play, setPlay] = useState(false)
  const [videoId, setVideo] = useState()
  const [channel, setChannel] = useState("")

  const playVideo = featuredVideo => {
    validatePlay(featuredVideo, setChannel, setVideo, setPlay)
  }

  const postData = data?.allContentfulResource?.edges[0]?.node
  const conversionPanel = data?.contentfulComponentConversionPanel
  const videoResource = postData?.videoResource
  const seo = postData?.pageSeo
  const heroImage = data?.contentfulComponentImage

  const breadStyle = {
    fontWeight: 400,
    textDecoration: "none",
    color: "white",
    transition: "color 200ms ease",
  }

  return (
    <Layout headerTheme={{ theme: "dark", background: "transparent" }}>
      <Seo
        title={seo?.seoTitle || postData?.title}
        description={seo?.seoDescription || postData?.description?.description}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || heroImage?.primaryImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <HeroWrapper>
        <OptimizedImage
          className="hero-image"
          loading="lazy"
          image={heroImage?.primaryImage?.gatsbyImageData}
          src={heroImage?.primaryImage?.file?.url}
          alt="hero-image"
        />
        <BreadCrumbFlex>
          <Link href="/">
            <span style={breadStyle}>Home</span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p>Resources</p>
            </span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p className="bc-big">Video</p>
            </span>
          </Link>
        </BreadCrumbFlex>
        <Kicker>VIDEO</Kicker>
        <Heading className="heading">{postData?.title}</Heading>
        {postData?.body && (
          <Text className="description">
            {postDetailResolveRichText(postData)}
          </Text>
        )}
      </HeroWrapper>
      <VideoSectionWrapper>
        <ThumbnailWrapper>
          <figure className="image-content">
            {postData.featuredImage?.file?.url ? (
              <div>
                <img
                  src={postData?.featuredImage?.file?.url}
                  alt="video thumbnail"
                />
              </div>
            ) : (
              <div className="emptyImg" />
            )}
          </figure>
          <div className="playicon">
            <span
              onClick={() => playVideo(videoResource)}
              role="button"
              aria-label="play"
              tabIndex={0}
              onKeyDown={e =>
                onKeyDown(e, () => playVideo(videoResource), "Enter")
              }
            >
              <img src="/icons/hero-play.svg" alt="watch video button" />
            </span>
          </div>
        </ThumbnailWrapper>
        <BlogSocialShareRow
          slug={postData?.slug}
          iconColor={"blue"}
          backgroundColor={"#F2F4F7"}
        />
      </VideoSectionWrapper>
      {typeof window !== "undefined" && play && (
        <VideoFrame>
          <ModalVideo
            channel={channel}
            isOpen={play}
            videoId={videoId}
            url={channel === "custom" && videoId}
            onClose={() => setPlay(false)}
            autoplay={true}
          />
        </VideoFrame>
      )}
      <ConversionPanelStandard component={conversionPanel} />
    </Layout>
  )
}

export default VideoDetailTemplate

export const VideoTemplateQuery = graphql`
  query VideoTemplateQuery($id: String) {
    allContentfulResource(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          pageSeo {
            graphImage {
              description
              title
              file {
                url
              }
            }
            seoTitle
            seoDescription
            canonical
            noIndex
            metaTags
            article
          }
          publishDate
          externalUrl
          category {
            title
            backgroundColor
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
          }
          description {
            description
          }
          body {
            raw
          }
          videoResource {
            id
            caption {
              raw
            }
            alignCaption
            contentful_id
            autoplay
            title
            videoUrl
            thumbnail {
              gatsbyImageData
              file {
                url
              }
            }
            videoFile {
              title
              description
              gatsbyImageData
              file {
                url
              }
            }
            internal {
              type
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "182594f4-83ef-5663-a500-ad9e6e8e904e" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      noTopPadding
      noBottomPadding
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
    }
    contentfulComponentImage(
      id: { eq: "92212009-cd40-5f22-9cec-be772545a7be" }
    ) {
      __typename
      id
      primaryImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
    }
  }
`
